<template>
  <div class="row">
    <div class="col-3">
      <animal :animal-id="animal" />
    </div>
    <div class="col-9 detail">
      <div class="row detail-header">
        <div class="col-4">
          <div class="row">
            <div class="col-2">
              <img
                :src="photoCreateur"
                v-if="hasPhotoCreateur"
              >
              <i
                class="las la-user la-3x"
                v-else
              />
            </div>
            <div class="col-10">
              <h6>{{ nameCreateur }}</h6>
              <small>{{ titreCreateur }}</small>
            </div>
          </div>
        </div>
        <div class="col-4">
          <slot name="header" />
        </div>
        <div class="col-4 text-right">
          {{ createAt|showTime }}
        </div>
      </div>
      <slot name="content" />
    </div>
  </div>
</template>

<script>
import Animal from '../identification/animal/animalVerticalDetail.vue';
import { mapGetters } from 'vuex';
export default {
    props: {
        animal: {type: String, required: true},
        createBy: {type: String, required: true},
        createAt: {type: String, required: true}
    },
    components:{ Animal },
    computed: {
        ...mapGetters({
            users: 'auth/users',
            typeComptes: 'auth/typeComptes',
        }),
        createdBy(){
            if(this.createBy !== null) {
                let u = this.users.find(item => item.uid === this.createBy)
                if(u) return u
            }
            return null
        },
        hasPhotoCreateur(){
            if(this.createdBy !== null) return this.createdBy.photo !== null
            return false
        },
        photoCreateur(){
            return  (this.hasPhotoCreateur) ?  this.createdBy.photo : null
        },
        nameCreateur(){
            return (this.createdBy !== null)? this.createdBy.fullname : ''
        },
        titreCreateur(){
            if(this.createdBy !== null){
                let t = this.typeComptes.find(item => item.uid === this.createdBy.typeCompte)
                if(t) return t.libelle
            }
            return '-'
        },
    }
}
</script>

<style lang="scss" scoped>
.detail{
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.17);
    border-radius: 10px;
    .detail-header{
        padding: 20px;
        background-color: #BB1B1B;
        color: white;
        h4,h6{color: white;};
    }
    .info{
        padding: 20px 0px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        cursor: pointer;
        &:hover{
            background: rgba(0, 0, 0, 0.1);
        };
        p{
            margin-left: 10px;
        }
    }
}
</style>